import React ,{useState} from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import SiteLayout from '@layouts/site-layout';
import Content, { HTMLContent } from '../components/Content'
import { navigate } from 'gatsby-link';


function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

export const BlogPostTemplate = ({
    content,
    contentComponent,
    description,
    tags,
    title,
    helmet,
}) => {
    const [formState,setFormState] = useState({name:''
    ,email:''
    ,PhoneNumber:''
    ,grade:''
})
    const PostContent = contentComponent || Content

    const handleChange = (e) => {
        // this.setState({ [e.target.name]: e.target.value })
        setFormState({...formState ,
            [e.target.name]: e.target.value})
        e.target.setCustomValidity("")
      
        
    }

    const handleSubmit = e => {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: {'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...formState
                // ...this.state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch(error => alert(error))
    }
    

    return (
        <section className="section top_margin_comn blog_post">
            {helmet || ''}
            <div className="container content">
                <div className="row">
                    <div className="col-md-12">
                        <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
                            {title}
                        </h1>
                        <p>{description}</p>
                        <PostContent content={content} />

                        {/* <p>dropdown - form</p> */}

     <div className="container"> 
      <div className="form-forfree-signup"> 
        <h4 className="commn_heading">Sign up for a FREE Demo class</h4>
            <form
                name="blogform"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
            >
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                    <label>
                        Don’t fill this out:{' '}
                        <input name="bot-field" onChange={handleChange} />
                    </label>
                </div>
                <div className="field">
                    <label className="label" htmlFor={'name'}>
                     Name *
                    </label>
                    <div className="control">
                        <input
                            className="input form-control"
                            type={'text'}
                            // required pattern="[A-Za-z]*"
                            required  pattern="[A-z ']*"
                            onInvalid={e => e.target.setCustomValidity("Enter Letters only")}
                            name={'name'}
                            onChange={handleChange}
                            id={'name'}
                            value={formState.name}
                            required={true}
                        />
                    </div>
                </div>
                <div className="field">
                    <label className="label" htmlFor={'Phone Number'}>
                    Contact Number *
                    </label>
                    <div className="control">
                        <input
                            className="input form-control"
                            required pattern="[0-9]*" 
                            minlength="7"
                            maxlength="10"
                            type={'text'}
                            name={'PhoneNumber'}
                            onChange={handleChange}
                            onInvalid={e => e.target.setCustomValidity(" Enter Numbers only")}
                            id={'Phone Number'}
                            value={formState.PhoneNumber}
                            required={true}
                        />
                    </div>
                </div> 
                <div className="field">
                    <label className="label" htmlFor={'email'}>
                        Email ID *
                    </label>
                    <div className="control">
                        <input
                            className="input form-control"
                            required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            type={'email'}
                            name={'email'}
                            onChange={handleChange}
                            id={'email'}
                            value={formState.email}
                            required={true}
                        />
                    </div>
                </div>  
                <div className="field mb-3">
                    <label className="label" >
                       Current Grade *
                    </label>
                    <div className="control">  
                        <select class="form-control" required="true" value={formState.grade}  name={'grade'} onChange={handleChange} >
                            <option value="" >Select Grade</option>
                            <option value="6">VI</option>
                            <option value="7">VII</option>
                            <option value="8">VIII</option>
                            <option value="9">IX</option>
                            <option value="10">X</option>
                            <option value="11">XI</option>
                            <option value="12">XII</option>
                        </select> 
                    </div>
                </div>  
                <div className="field">
                    <button className="button pink_btn" type="submit">
                        SUBMIT
                    </button>
                </div>
        </form> 
        </div>
    </div>


                         
                        {/* {tags && tags.length ? (
                            <div style={{ marginTop: `4rem` }}>
                                <h4>Tags</h4>
                                <ul className="taglist">
                                    {tags.map(tag => (
                                        <li key={tag + `tag`}>
                                            <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ) : null} */}
                    </div>
                </div>
            </div>
        </section>
    )
}

BlogPostTemplate.propTypes = {
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    description: PropTypes.string,
    title: PropTypes.string,
    helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
    const { markdownRemark: post } = data;

    console.log('post', post);
    console.log(post['frontmatter']['featuredimage']['childImageSharp']['fluid']['src'])
    let img=post['frontmatter']['featuredimage']['childImageSharp']['fluid']['src']
 

    return (
        <SiteLayout title={post.frontmatter.title} description={post.frontmatter.description} image={img}>
            <BlogPostTemplate
                content={post.html}
                contentComponent={HTMLContent}
                description={post.frontmatter.description}
                helmet={
                    <Helmet titleTemplate="%s | Blog">
                        <title>{`${post.frontmatter.title}`}</title>
                        <meta
                            name="description"
                            content={`${post.frontmatter.description}`}
                        />
                    </Helmet>
                }
                tags={post.frontmatter.tags}
                title={post.frontmatter.title}
                image={img}
            />
        </SiteLayout>
    )
}

BlogPost.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.object,
    }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
        featuredimage {
            childImageSharp {
              fluid(maxWidth: 120, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
        }
      }
    }
  }
`
